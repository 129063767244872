'use client';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import Loader from '@/components/LoaderFile/Loader';

const Home = () => {
  const router = useRouter();
  const defaultLat = 43.653226; // Latitude for Toronto
  const defaultLon = -79.3831843; // Longitude for Toronto

  useEffect(() => {
    const storedLat = localStorage.getItem('lat');
    const storedLon = localStorage.getItem('lon');

    const lat = storedLat !== null ? parseFloat(storedLat) : defaultLat;
    const lon = storedLon !== null ? parseFloat(storedLon) : defaultLon;

    router.push(`/city?lat=${lat}&lon=${lon}`);
  }, [router]);

  return (
    <Loader />
  );
};

export default Home;
