"use client"
import React from 'react'
import Navbar from '../navbar'
import Footer from '../footer/page'
import Image from 'next/image'
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation'

const Loader = () => {
  return (
    <div className='h-screen flex flex-col justify-between'>
    <Navbar className={`bg-[#3BC3F2] w-full`}/>
    <div className='flex justify-center items-center'>
        {/* <Image src="/loadingAnimation.gif" width={200} height={200} alt="loader"/> */}
        <LoadingAnimation/>
    </div>
    <Footer/>
    </div>
  )
}

export default Loader