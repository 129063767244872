'use client'
import type { NextPage } from "next";
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from "next/link";
import { Fredoka } from "next/font/google";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { feathersClient } from "../connection/page";
const fredoka = Fredoka({ subsets: ["latin"] });

const Footer: NextPage = () => {
  const [loading, setLoading] = useState(false);
  const [backendVersion, setBackendVersion] = useState('');
  const [frontendVersion, setFrontendVersion] = useState('');

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [loading]);

  useEffect(() => {
    const fetchBackendVersion = async () => {
      try {
        const response = await feathersClient.service('/api/v1/app-version').find();
        const versionString = `${response.version.major}.${response.version.minor}.${response.version.patch}`;
        //localStorage.setItem('backendVersion', versionString);
        sessionStorage.setItem('backendVersion', versionString);
        setBackendVersion(versionString);
      } catch (error) {
        console.error('Failed to fetch backend version:', error);
      }
    };

    const fetchFrontendVersion = async () => {
      try {
        const response = await fetch('/api/app-version');
        const data = await response.json();
        const versionString = `${data.data.version.major}.${data.data.version.minor}.${data.data.version.patch}`;
        //localStorage.setItem('frontendVersion', versionString);
        sessionStorage.setItem('frontendVersion', versionString);
        setFrontendVersion(versionString);
      } catch (error) {
        console.error('Failed to fetch frontend version:', error);
      }
    };

    // const storedBackendVersion = localStorage.getItem('backendVersion');
    // const storedFrontendVersion = localStorage.getItem('frontendVersion');

    const storedBackendVersion = sessionStorage.getItem('backendVersion');
    const storedFrontendVersion = sessionStorage.getItem('frontendVersion');

    if(storedBackendVersion) {
      setBackendVersion(storedBackendVersion);
    } else{
      fetchBackendVersion();
    }

    if(storedFrontendVersion) {
      setFrontendVersion(storedFrontendVersion);
    } else{
      fetchFrontendVersion();
    }

  }, []);

  
  const handleLinkClick = () => {
    setLoading(true);
  };

  return (
    <div className={`py-7 px-4 md:px-8 lg:px-16 bg-[#3bc3f2d9] text-white font-base-medium ${fredoka.className}`}>
      {loading && 
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 bg-white w-screen h-screen">
          <div className='w-full h-full absolute flex items-center justify-center text-black'>
            <LoadingAnimation />
          </div>
        </div>
      }
      <div className="lg:container lg:mx-auto">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-14 md:gap-8">
          <div className="flex items-start md:items-center justify-start">
            <Link href='/'>
              <img className="w-[131px] h-[66px] object-cover" alt="Logo" src="/Logo.png" />
            </Link>
          </div>
          <div className="flex-1 flex md:flex-row flex-col max-md:gap-4 items-start md:items-center text-start md:text-center justify-center gap-8 md:gap-16 font-medium">
            <Link className="hover:text-black" href="/contactus" onClick={handleLinkClick}>Contact Us</Link>
            <Link className="hover:text-black" href="/terms-and-conditions" onClick={handleLinkClick}>Terms & Conditions</Link>
            <Link className="hover:text-black" href="/faq" onClick={handleLinkClick}>FAQs</Link>
          </div>
          <div className="flex flex-col items-start md:items-center justify-start gap-4 md:gap-5">
            <div className="font-medium">Social Media</div>
            <div className="flex items-end justify-start gap-4">
              <Link legacyBehavior href="https://www.facebook.com/groups/KarmaTickets" passHref>
                <a target="_blank" rel="noopener noreferrer">
                  <img className="w-6 h-6" alt="Facebook" src="/facebook.svg" />
                </a>
              </Link>
              {/* <Link legacyBehavior href="https://instagram.com" passHref>
                <a target="_blank" rel="noopener noreferrer">
                  <img className="w-6 h-6" alt="Instagram" src="/instagram.svg" />
                </a>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="mt-8 text-start md:text-center">
          <p className="leading-6">
            *Karma Tickets is an independently owned and operated, licensed ticket broker that specializes in obtaining premium and sold out tickets to events nationwide. Ticket prices are dependent upon the current market price, which is usually above the face value printed on the tickets. We are not affiliated with Ticketmaster or any venues, teams, performers, or organizations.
          </p>
        </div>
        <hr className="border-t-[1px] border-solid border-dodgerblue my-8" />
        <div className="flex flex-col sm:flex-row sm:gap-1 items-center justify-center">
          <p className="font-medium text-center text-white">&copy; 2024 KarmaTickets.ca | All Rights Reserved</p>
          <p className="font-medium text-center text-white"><span className="hidden sm:inline">|</span> {frontendVersion} | {backendVersion}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
